import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MeetingAgendaDetailsDTO, MeetingAgendaFormDTO, MeetingAgendaListDTO } from '../dto/meeting-agenda.dto';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';

@Injectable({
  providedIn: 'root',
})

export class MeetingAgendaService implements SubCRUDServiceInterface {

  protected apiUrl = environment.apiUrl + '/meetings';

   constructor(protected http: HttpClient) {}

  list(meetingId: number): Observable<MeetingAgendaListDTO[]> {
    const url = `${this.apiUrl}/${meetingId}/agendas`;
    return this.http.get<MeetingAgendaListDTO[]>(url);
  }

  create(meetingId: number, item: MeetingAgendaFormDTO): Observable<MeetingAgendaFormDTO> {
    const url = `${this.apiUrl}/${meetingId}/agendas`;
    return this.http.post<MeetingAgendaFormDTO>(url, item);
  }

  get(
    meetingId: number,
    agendaId: number
  ): Observable<MeetingAgendaDetailsDTO> {
    return this.http.get<MeetingAgendaDetailsDTO>(
      `${this.apiUrl}/${meetingId}/agendas/${agendaId}`
    );
  }

  update(
    meetingId: number,
    agendaId: number,
    item: MeetingAgendaFormDTO
  ): Observable<MeetingAgendaFormDTO> {
    return this.http.put<MeetingAgendaFormDTO>(
      `${this.apiUrl}/${meetingId}/agendas/${agendaId}`,
      item
    );
  }

  delete(meetingId: number, ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${meetingId}/agendas/delete`, { ids });
  }

  getSummaryChart(meetingId: number): Observable<any> {
    const url = `${this.apiUrl}/${meetingId}/action-plan-summary`;
    return this.http.get<any>(url);
  }
}
