import { FormGroup } from '@angular/forms';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';

import { Injectable } from '@angular/core';
import { MeetingAgendaDetailsDTO, MeetingAgendaFormDTO, MeetingAgendaListDTO } from '../dto/meeting-agenda.dto';

@Injectable({
  providedIn: 'root',
})
export class MeetingAgendaConversion
  implements
    MainConversionInterface<MeetingAgendaListDTO, MeetingAgendaFormDTO, MeetingAgendaDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): MeetingAgendaListDTO[] {
    if (!response) {
      return [];
    }

    const result = response.map((item) => {
      return {
        id: item.id,
        meeting_id: item.meeting_id,
        title: item.title,
        meeting_title: item.meeting_title,
        // time_from: item.time_from,
        // time_to: item.time_to,
        meeting_location: item.meeting_location,
        meeting_start_time: item.meeting_start_time,
        meeting_end_time: item.meeting_end_time,
        meeting_date: item.meeting_date,
        created_at: item.created_at,
        updated_at: item.updated_at,
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): MeetingAgendaFormDTO {
    return {
      id: formGroup.get('id')?.value,
      title: formGroup.get('AgendaTitle')?.value,
      // time_from: formGroup.get('TimeFrom')?.value,
      // time_to: formGroup.get('TimeTo')?.value,
    };
  }

  resToForm(response: any): any {
    return {
      id: response.id,
      AgendaTitle: response.title,
      // TimeFrom: response.time_from,
      // TimeTo: response.time_to,
    };
  }

  resToDetails(response: any): MeetingAgendaDetailsDTO {
    return {
      id: response.id,
      meeting_id: response.meeting_id,
      title: response.title,
      // time_from: response.time_from,
      // time_to: response.time_to,
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by_id),
      created_at: response.created_at,
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by_id),
      updated_at: response.updated_at,
    };
  }

}
